import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Col, message, Row, Spin, Tabs} from "antd";
import {Spacer} from "../../../../../../components/DesignSystem/Spacer/Spacer";
import {CenteredDesign} from "../../../../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {ExportMainForm} from "../../../../../../components/Export/ExportMainForm";
import {exportService} from "../../../../../../services/exportService";
import {AlertIfMessage} from "../../../../../../components/DesignSystem/AlertIfMessage/AlertIfMessage";
import {exportFormService, STEPS} from "../exportFormService";
import {EditExportDetailHeader} from "./EditExportDetailHeader";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";
import {ExportStatus, ExportStatusFinished} from "../snapshots/ExportStatus";
import {ExportNewFlowForm} from "../../../../../../components/Export/ExportNewFlowForm";
import {processStepService} from "../../../../../../services/processStepService";
import {userService} from "../../../../../../services/user.service";
import {ExportSnapshotsDetail} from "../snapshots/ExportSnapshotsDetail";
import {AIAssistantPage} from "../../../../ai-assistant/AIAssistantPage";
import {DashboardsPage} from "../../../../dashboards/DashboardsPage";

export const NewExportPage = () => {

    let history = useHistory();

    let {exportId, section} = useParams();

    const [user, setUser] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [loadingContext, setLoadingContext] = useState({loading: false, tip: undefined});
    const [data, setData] = useState(undefined);
    const [exportSnapshotId, setExportSnapshotId] = useState(undefined);
    const [showExportStatus, setShowExportStatus] = useState(false);
    const [exportSnapshotStatus, setExportSnapshotStatus] = useState(undefined);
    const [mode, setMode] = useState("list");
    const [size, setSize] = useState({sm: 24, lg: 20});
    const [selectedTab, setSelectedTab] = useState(section || 'definition');

    useEffect(() => {
        userService.me().then(response => {
            setUser(response.data);
            if (exportId) {
                fetchExport();
            }
        });
    }, [exportId]); // eslint-disable-line react-hooks/exhaustive-deps

    function fetchExport() {
        setLoadingContext({loading: true})
        exportService.fetchExport(exportId).then(response => {
            setData(response.data);
        }).finally(() => {
            setLoadingContext({loading: false});
        })
    }

    const onFinish = values => {

        setLoadingContext({loading: true});

        if (values.step === STEPS.EXPORT_MAPPING_STEP) {
            return exportService.updateExportMappingStep(exportId, values).then(response => {
                setData(response.data);
                return resolve();
            }).catch(e => {
                return reject(e);
            });
        }

    };

    function startExport(exportId) {
        exportService.startExport(exportId).then(response => {
            message.info("Export has been started");
            setShowExportStatus(false);
            setExportSnapshotId(response.data);
            // setReloadTable(Math.random());
        }).catch(error => {
            setError(getErrorFromResponse(error));
            message.error(getErrorFromResponse(error));
        })
    }

    const reject = (e) => {
        setLoadingContext({loading: false});
        return Promise.reject(e);
    }

    const resolve = () => {
        setLoadingContext({loading: false});
        return Promise.resolve();
    }

    const onClose = () => {
        window.location.reload(false);
    }

    const onSeeDetails = (exportId) => {
        history.push(`/exports/${exportId}/snapshots`)
    }

    const handleOnChange = (step, action, values, inputs) => {
        return exportFormService.getData(step, action, values, inputs);
    }

    const handleModeChanged = (mode) => {
        setSize({sm: mode === "edit" ? 24 : 20, lg: mode === "edit" ? 24 : 20});
        setMode(mode);
    }

    function handleOnFinish(status, message) {
        setExportSnapshotId(undefined);
        setExportSnapshotStatus({status: status, message: message});
        setShowExportStatus(true);
    }

    const tabs = [];

    if (data) {
        tabs.push({
            key: 'snapshots',
            label: 'Snapshots',
            children: (selectedTab === 'snapshots' && <ExportSnapshotsDetail object={data?.object}
                                                                             exportId={exportId}
                                                                             lastSuccessfulExecution={data?.export.lastSuccessfulExecution}
                                                                             statistics={data?.statistics}/>),
        });
    }

    tabs.push({
        key: 'definition',
        label: 'Definition',
    });

    if (data && data.export?.template != null) {
        tabs.push({
            key: 'dashboard',
            label: 'Dashboard',
            disabled: data?.export.lastSuccessfulExecution === undefined || data?.export?.definitionState !== 'FINAL'
        });
    }

    if (user && user.companyPlan.aiSettings.enabled && data && data.export?.definitionState === 'FINAL') {
        tabs.push({
            key: 'ai-assistant',
            label: 'AI Assistant',
            disabled: data?.export.lastSuccessfulExecution === undefined
        });
    }

    const onChange = (key) => {
        setSelectedTab(key);

        if (key === 'ai-assistant') {
            setSize({sm: 24, lg: 24});
            return
        }
    }

    const closeAiAssistant = () => {
        window.location.reload();
    }

    return <CenteredDesign sm={size.sm} lg={size.lg}>
        <Spin spinning={loadingContext.loading} tip={loadingContext.tip}>
            <Row justify="center">
                <Col sm={24}>
                    {selectedTab !== 'ai-assistant' && (<>
                        <Spacer/>
                        <AlertIfMessage message={error}/>
                        {exportSnapshotId !== undefined &&
                            <ExportStatus exportId={exportId}
                                          exportSnapshotId={exportSnapshotId}
                                          onFinish={handleOnFinish}/>}
                        {showExportStatus && <ExportStatusFinished
                            exportName={data.export.name}
                            status={exportSnapshotStatus.status}
                            message={exportSnapshotStatus.message}
                            documentPublicUrl={data.export.documentPublicUrl}/>}
                        {mode === "list" && data && (<EditExportDetailHeader
                            companySubscriptionState={user.companyPlan.companySubscriptionState}
                            exportDefinition={data.export}
                            subscriptionLimit={data.subscriptionLimit}
                            statistics={data.statistics}
                            onChangedName={fetchExport}
                            onSeeDetails={onSeeDetails}
                            schedule={data?.schedule}
                            onStartExport={startExport}/>)}
                        <Spacer/>
                        {(exportId !== undefined && mode !== 'edit') &&
                            <Tabs items={tabs} onChange={onChange} defaultActiveKey={selectedTab} activeKey={selectedTab}/>}
                    </>)}
                    {selectedTab === 'definition' && <div>
                        <ExportNewFlowForm data={data}
                                           exportMappingType={user?.exportMappingType}
                                           processStepService={processStepService}
                                           onModeChanged={handleModeChanged}/>
                        {mode === "list" && <ExportMainForm exportId={exportId}
                                                            editableMode={data?.editableMode}
                                                            data={data}
                                                            onFinish={onFinish}
                                                            onClose={onClose}
                                                            onChange={handleOnChange}/>}
                    </div>}
                    {selectedTab === 'dashboard' && <DashboardsPage user={user} exportDefinition={data?.export}/>}
                    {selectedTab === 'ai-assistant' && <AIAssistantPage onBack={closeAiAssistant}/>}
                </Col>
            </Row>

        </Spin></CenteredDesign>

}

NewExportPage.propTypes = {
    user: PropTypes.object.isRequired
};