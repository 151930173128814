import React from "react";
import {ExportIcon} from "../../../../../../components/Export/ExportIcon/ExportIcon";
import {Col, Row, message, Flex,} from "antd";
import {Title} from "../../../../../../components/DesignSystem/Typography/Title/Title";
import {InlineEdit} from "../../../../../../components/DesignSystem/InlineEdit/InlineEdit";
import {exportService} from "../../../../../../services/exportService";
import {RunExportButton} from "../../../../../../components/ExportDetail/table/RunExportButton";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";
import {ExportDocumentPublicUrlButton} from "../../../../../../components/Export/ExportDocumentPublicUrlButton";
import {DraftIndicator} from "../../../../../../components/Export/DraftIndicator";

export const EditExportDetailHeader = ({
                                           exportDefinition,
                                           subscriptionLimit,
                                           companySubscriptionState,
                                           onChangedName,
                                           onStartExport,
                                       }) => {

    const handleNameChange = (value) => {
        exportService.updateExportName(exportDefinition.id, value).then(() => {
            message.info("Export name has been updated");
            onChangedName();
        }).catch(error => {
            message.error("Export name hasn't been updated. " + getErrorFromResponse(error));
        })
    }

    return <Row align="middle">
            <Col sm={16}>
                <Flex align="center">
                    <ExportIcon source={exportDefinition.source} target={exportDefinition.target}/>
                    <Flex align={"middle"}>
                        <Title>
                            <InlineEdit onChange={handleNameChange} value={exportDefinition.name}/>
                        </Title>
                        <DraftIndicator definitionState={exportDefinition.definitionState}/>
                    </Flex>
                </Flex>
            </Col>
            <Col sm={8}>
                <div style={{textAlign: "right", paddingRight: "15px"}}>
                    <RunExportButton record={exportDefinition} subscriptionLimit={subscriptionLimit}
                                     type="default"
                                     companySubscriptionState={companySubscriptionState}
                                     onClick={() => onStartExport(exportDefinition.id)}/>{' '}
                    {exportDefinition.documentPublicUrl !== undefined && (<ExportDocumentPublicUrlButton
                        type={exportDefinition.target}
                        documentPublicUrl={exportDefinition.documentPublicUrl}/>)}
                </div>
            </Col>
        </Row>

}

EditExportDetailHeader.propTypes = {
    exportDefinition: PropTypes.object.isRequired,
    subscriptionLimit: PropTypes.number.isRequired,
    companySubscriptionState: PropTypes.string.isRequired,
    onChangedName: PropTypes.func.isRequired,
    onStartExport: PropTypes.func.isRequired,
    onSeeDetails: PropTypes.func.isRequired
}