import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Empty, message} from "antd";
import {CenteredDesign} from "../../../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {Spacer} from "../../../../../components/DesignSystem/Spacer/Spacer";
import {ExportsDetailHeader} from "../../../../../components/ExportDetail/ExportsDetailHeader";
import {ExportsTable} from "../../../../../components/ExportDetail/table/ExportsTable";
import {exportService} from "../../../../../services/exportService";
import {getErrorFromResponse} from "../../../../../utils/error.utils";
import {TextCenter} from "../../../../../components/DesignSystem/Center/TextCenter";
import PropTypes from "prop-types";
import {useCookies} from "react-cookie";
import {PIPEDRIVE_COOKIE} from "../../../ouath/PipedriveCallback";
import {PipedriveProcessCookiesDialog} from "./PipedriveProcessCookiesDialog";
import {finishPipedriveAppRegistrationProcess} from "./PipedriveProcessCookies";

export const ExportsPage = ({user}) => {

    let history = useHistory();

    const [cookies, , removeCookies] = useCookies([PIPEDRIVE_COOKIE]);

    const [visible, setVisible] = useState(false);
    const [reloadExportsTable, setReloadExportsTable] = useState(undefined);
    const [showPipedriveProcessCookiesDialog, setShowPipedriveProcessCookiesDialog] = useState(true);

    function fetchExports(page, size) {
        return exportService.fetchAllExports(page, size).then(response => {
            return Promise.resolve(response);
        })
    }

    function finishPipedriveProcess() {
        setShowPipedriveProcessCookiesDialog(false);
        finishPipedriveAppRegistrationProcess(cookies, removeCookies, history, message);
    }

    function cancelPipedriveProcess() {
        setShowPipedriveProcessCookiesDialog(false);
        removeCookies(PIPEDRIVE_COOKIE);
    }

    function handleCreateNewExport() {
        history.push(`/exports/new`);
    }

    function startExport(exportId) {
        exportService.startExport(exportId).then(() => {
            message.info("Export has been started");
        }).catch(error => {
            message.error(getErrorFromResponse(error));
        })
    }

    function handleSelect(action, record) {
        if (action === 'delete') {
            exportService.deleteExport(record.id).then(() => {
                message.info(`The export with name ${record.name} was deleted`);
                setReloadExportsTable(Math.random());
            });
        } else if (action === 'edit') {
            history.push(`/exports/${record.id}/definition`);
        } else if (action === 'details') {
            history.push(`/exports/${record.id}/snapshots`);
        } else if (action === 'run') {
            startExport(record.id);
        } else if (action === 'copy') {
            exportService.copyExport(record.id).then(() => {
                message.info(`The export with name ${record.name} was copied`);
                setReloadExportsTable(Math.random());
            }).catch(e => {
                message.error(`The export with name ${record.name} copy failed`);
            });
        }
    }

    const customizeRenderEmpty = () => (
        <Empty style={{padding: "50px"}}
               imageStyle={{
                   height: 80,
               }}
               description="There are no exports defined yet">
            <Spacer/>
            <Button type={"primary"} onClick={handleCreateNewExport}>Create a first export</Button>
            <Spacer size={"large"}/>
            <TextCenter>If you need more info please visit our documentation about <a href={"https://www.apimoon.com/help/how-to-create-export"} target="_blank" rel="noreferrer">How
                to create an export.</a></TextCenter>
        </Empty>
    );

    return <CenteredDesign>
        {cookies[PIPEDRIVE_COOKIE] &&
            <PipedriveProcessCookiesDialog open={showPipedriveProcessCookiesDialog} handleOk={finishPipedriveProcess}
                                           handleCancel={cancelPipedriveProcess}/>}
        {!cookies[PIPEDRIVE_COOKIE] &&
            <><ExportsDetailHeader setVisible={setVisible} onCreateNewExport={handleCreateNewExport}
                                 companySubscriptionState={user.companyPlan.companySubscriptionState}/>
                <Spacer/>
                <Spacer/>
                <ExportsTable
                    renderEmpty={customizeRenderEmpty}
                    user={user}
                    fetchData={fetchExports} reload={reloadExportsTable}
                    showPreferences={visible}
                    handleSelect={handleSelect}
                />
        </>
        }
    </CenteredDesign>

}

ExportsPage.propTypes = {
    user: PropTypes.object.isRequired
}