import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Col, Row} from "antd";
import {exportService} from "../../../../../../services/exportService";
import {ExportSnapshotsTable} from "../../../../../../components/ExportSnapshot/table/ExportSnapshotsTable";
import PropTypes from "prop-types";
import {ExportSchedulePanel} from "../schedule/ExportSchedulePanel";
import {useCookies} from "react-cookie";
import {StatisticsHeader} from "../StatisticsHeader";

export const ExportSnapshotsDetail = ({user, object, statistics}) => {

    const SHOW_FAILED_ONLY = "show_failed_only";

    let {exportId} = useParams();

    const [cookies, setCookies] = useCookies([SHOW_FAILED_ONLY]);

    const [reloadTable, setReloadTable] = useState(undefined);

    useEffect(() => {
        if (cookies[SHOW_FAILED_ONLY] === undefined) {
            setCookies(SHOW_FAILED_ONLY, false, {path: '/'});
        }

    }, [exportId]); // eslint-disable-line react-hooks/exhaustive-deps


    function fetchExportSnapshots(page, size) {
        return exportService.fetchAllExportSnapshots(exportId, cookies[SHOW_FAILED_ONLY], page, size).then(response => {
            return Promise.resolve(response);
        })
    }

    function handleShowFailedOnlyChange(e) {
        setCookies(SHOW_FAILED_ONLY, e, {path: '/'});
        setReloadTable(Math.random());
    }

    return <Row gutter={20}>
        <Col sm={24}>
            <StatisticsHeader object={object} statistics={statistics}/>
        </Col>
        <Col sm={14}>
            <ExportSnapshotsTable fetchData={fetchExportSnapshots}
                                  reload={reloadTable}
                                  showFailedOnly={cookies[SHOW_FAILED_ONLY]}
                                  onShowFailedOnlyChange={handleShowFailedOnlyChange}
            />
        </Col>
        <Col sm={10}>
            <ExportSchedulePanel user={user}/>
        </Col>
    </Row>

}

ExportSnapshotsDetail.propTypes = {
    user: PropTypes.object.isRequired
};