import React, {useEffect, useRef, useState} from "react";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {Card, Skeleton, Result} from "antd";
import {useHistory, useParams} from "react-router-dom";
import {exportService} from "../../../services/exportService";
import PropTypes from "prop-types";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {getErrorFromResponse} from "../../../utils/error.utils";
import {DashboardPanel} from "./DashboardPanel";

export const DashboardsPage = ({exportDefinition}) => {

    let {exportId} = useParams();
    let history = useHistory();

    const [loadingContext, setLoadingContext] = useState({loading: false, tip: undefined});
    const [dashboard, setDashboard] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        fetchExport();
    }, [exportId]); // eslint-disable-line react-hooks/exhaustive-deps

    function fetchExport() {
        setLoadingContext({loading: true});
        setErrorMessage(undefined);

        exportService.fetchDashboardExport(exportId).then(response => {
            setDashboard(response.data);
        }).catch(error => {
            setErrorMessage(getErrorFromResponse(error));
        }).finally(() => {
            setLoadingContext({loading: false});
        })


    }

    const exportRef = useRef();

    const handleExportToPDF = async () => {
        const element = exportRef.current;
        const canvas = await html2canvas(element, {useCORS: true}); // Ensures cross-origin images are included
        const dataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        const imgWidth = 190; // Adjust width as needed
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

        let position = 0; // Top position for new pages
        pdf.addImage(dataURL, 'PNG', 10, position, imgWidth, imgHeight);

        if (imgHeight > pageHeight) {
            let heightLeft = imgHeight - pageHeight;
            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(dataURL, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
        }

        pdf.save('export.pdf');
    };

    if (errorMessage !== undefined) {

        return <CenteredDesign><Result
            status="warning"
            title="A required dashboard can't be loaded."
            subTitle={errorMessage}
        /></CenteredDesign>

    }

    if (dashboard === undefined || exportDefinition === undefined) {
        return <Skeleton/>
    }

    return <Card ref={exportRef}>
            <DashboardPanel dashboard={dashboard} lastSuccessfulExecution={exportDefinition.lastSuccessfulExecution} onExportToPDF={handleExportToPDF}/>
        </Card>
}

DashboardsPage.propTypes = {
    user: PropTypes.object.isRequired
};