import React, {useEffect} from 'react';
import {Tabs} from "antd";
import {ExportPreviewTable} from "./ExportPreviewTable";

export const ExportPreview = ({data}) => {

    const [tabs, setTabs] = React.useState([]);

    useEffect(() => {

        if (!data) {
            return;
        }

        setTabs(data.sheets.map((item, index) => {
            return {
                key: index + 1,
                label: item.name,
                children: <ExportPreviewTable data={item.data} columnNames={item.columns} />
            }
        }));

    }, data);

    return <Tabs defaultActiveKey="1" items={tabs} />
}