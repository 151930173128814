import React from "react";
import {Link} from "react-router-dom";
import {BigColumn} from "../../DesignSystem/BigColumn/BigColumn";
import {FieldTimeOutlined} from "@ant-design/icons";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import ActionDropdown from "../../DesignSystem/ActionDropdown/ActionDropdown";
import {ExportIcon} from "../../Export/ExportIcon/ExportIcon";
import {HumanReadableDate} from "../../DesignSystem/Moment/HumanReadableDate";
import CopyOutlined from "@ant-design/icons/lib/icons/CopyOutlined";
import {RunExportButton} from "./RunExportButton";
import {ExportDocumentPublicUrl} from "../../Export/ExportDocumentPublicUrl";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import {Flex, Tag} from "antd";
import {ShowDashboardButton} from "./ShowDashboardButton";
import {DraftIndicator} from "../../Export/DraftIndicator";

export const buttons = (handleSelect, record, user) => {

    return <>
        {record.template != null && (<ShowDashboardButton record={record}/>)}
        <RunExportButton record={record} subscriptionLimit={record.subscriptionLimit}
                         companySubscriptionState={user.companyPlan.companySubscriptionState} onClick={() => handleSelect('run', record)}/>{' '}
        {' '}
        <ActionDropdown handleSelect={handleSelect} data={record} items={[
            {
                title: 'History or schedule',
                id: 'details',
                icon: <FieldTimeOutlined/>,
                disabled: user.companyPlan.companySubscriptionState !== 'OK'
            },
            {
                title: 'Edit definition',
                id: 'edit',
                icon: <SettingOutlined/>,
                disabled: user.companyPlan.companySubscriptionState !== 'OK'
            },
            {
                title: 'Copy',
                id: 'copy',
                icon: <CopyOutlined/>,
                confirmation: {
                    title: 'Confirmation',
                    content: `Do you really want to copy the export with name <b>${record.name}</b>?`
                },
                disabled: user.companyPlan.companySubscriptionState !== 'OK'
            },
            {
                title: 'Delete',
                id: 'delete',
                icon: <DeleteOutlined/>,
                confirmation: {
                    title: 'Confirmation',
                    content: `Do you really want to delete the export with name <b>${record.name}</b>?`
                }
            }
        ]}>
        </ActionDropdown>
    </>

}

const nextScheduleInfo = (nextSchedule, scheduleEnabled) => {

    if (nextSchedule === null) {
        return (", a next run is not scheduled")
    } else if (scheduleEnabled) {
        return (<>, next run is scheduled <HumanReadableDate date={nextSchedule}/></>)
    } else {
        return (<>, next run is scheduled <HumanReadableDate date={nextSchedule}/> <b>but disabled</b></>)
    }

}

export const subtitle = (record) => {

    return <>
        {record.lastSuccessfulExecution && (<>Last run was <HumanReadableDate date={record.lastSuccessfulExecution}/></>)}
        {record.lastSuccessfulExecution === null && ("No successful runs yet")}
        {nextScheduleInfo(record.nextSchedule, record.scheduleEnabled)}
        {<ExportDocumentPublicUrl documentPublicUrl={record.documentPublicUrl} type={record?.target}/>}
    </>

}

export const columns = (user, handleSelect) => [
    {
        title: 'Object',
        dataIndex: 'object',
        key: 'object',
        visible: true,
        render: (_, record) => <BigColumn
            iconSize={"120px"}
            icon={<ExportIcon size={"small"} source={record.source} target={record.target}/>}
            name={<ExportName record={record}/>} type={record.type}
            subtitle={subtitle(record)}
            extra={buttons(handleSelect, record, user)}
            createdAt={record.createdAt}/>
    }
];

const ExportName = ({record}) => {

    return <Flex align={"middle"}>
        <Link to={`/exports/${record.id}/snapshots`}>{record.name}</Link>{'  '}
        <DraftIndicator definitionState={record.definitionState}/>
    </Flex>

}