import {axiosService} from "../axios";

export const assistantService = {

    fetchPreview: (exportId) => {
        return axiosService.get(`/api/assistant/${exportId}/preview`)
    },

    ask: (exportId, message) => {
        return axiosService.post(`/api/assistant/${exportId}/ask`, {message: message})
    }


}