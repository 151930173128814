import React from 'react';
import {UserOutlined} from "@ant-design/icons";
import {Sender, Bubble, useXAgent, useXChat} from "@ant-design/x";
import styles from "./AIAssistantChat.module.css";
import {assistantService} from "../../../services/assistantService";
import {Table, Row, Col} from "antd";
import {NumberFormat} from "../../../components/DesignSystem/NumberFormat/NumberFormat";

export const AIAssistantChat = ({exportId}) => {

    const [content, setContent] = React.useState('');

    const roles = {
        ai: {
            placement: 'start',
            avatar: {
                icon: <UserOutlined/>,
                style: {
                    background: '#fde3cf',
                },
            },
            typing: {
                step: 5,
                interval: 20,
            },
            style: {
                maxWidth: 600,
            },
        },
        local: {
            placement: 'end',
            avatar: {
                icon: <UserOutlined/>,
                style: {
                    background: '#87d068',
                },
            },
        },
    };

    // Agent for request
    const [agent] = useXAgent({
        request: async ({message}, {onSuccess, onError}) => {
            assistantService.ask(exportId, message).then(response => {
                onSuccess(response.data);
            }).catch(error => {
                console.log(error);
                onError(new Error(error));
            })
        }
    });

    // Chat messages
    const {onRequest, messages} = useXChat({
        agent,
        requestPlaceholder: 'Waiting...',
        requestFallback: 'We’re experiencing some issues processing your query right now. Please try again later, or contact support if the issue persists.',
    });

    return <div className={styles.chat}>

        <Bubble.List
            roles={roles}
            style={{
                maxHeight: '100%',
                minHeight: '100%',
            }}
            items={messages.map(({id, message, status}) => ({
                key: id,
                loading: status === 'loading',
                role: status === 'local' ? 'local' : 'ai',
                content: formatMessage(message),
            }))}
        />
        <Sender loading={agent.isRequesting()} style={{marginTop: 'auto'}}
                value={content}
                onChange={setContent}
                allowSpeech={true}
                placeholder="How can I help you?"
                onSubmit={(nextContent) => {
                    onRequest(nextContent);
                    setContent('');
                }}
        /></div>

}

const formatMessage = (message) => {

    if (typeof message === 'string') {
        return <>{message}</>
    }

    if (message.type !== 'dataframe') {
        return <>{message.value}</>
    } else {

        const data = JSON.parse(message.value);

        const columns = data.schema.fields.map(field => {
            return {
                title: field.name,
                dataIndex: field.name,
                key: field.name,
                width: 100,
                render: (value) => formatValue(field.type, value),
                onHeaderCell: () => ({
                    style: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, // Ellipsis for long headers
                }),
                onCell: () => ({
                    style: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, // Ellipsis for long cells
                }),
            }
        });

        return <Row>
            <Col span={24}>
                <Table columns={columns} dataSource={data.data} pagination={false} sticky={true} size={"small"} scroll={{
                    y: '75vh', x: 'max-content',
                }}/>
            </Col>
        </Row>
    }
}

const formatValue = (type, value) => {

    if (type === 'integer' || type === 'number') {
        return <NumberFormat value={value}/>
    }

    return value;
}