import {Spacer} from "../../../../../components/DesignSystem/Spacer/Spacer";
import {Header} from "../../../../../components/DesignSystem/Header/Header";
import {Col, Row} from "antd";
import {HeaderItem} from "../../../../../components/DesignSystem/Header/HeaderItem";
import {UpperCaseText} from "../../../../../components/DesignSystem/Typography/UpperCaseText/UpperCaseText";
import {NumberFormat} from "../../../../../components/DesignSystem/NumberFormat/NumberFormat";
import React from "react";

export const StatisticsHeader = ({object, statistics}) => {

    const values = statistics !== null ? statistics : {
        runsUsed: 0, rowsUsed: 0, failedRuns: 0
    };

    return <Header>
        <Row>
            <Col sm={24}>
                {object && (<HeaderItem label={"Object"} value={<UpperCaseText text={object}/>}/>)}
                <HeaderItem label={"Used runs"} value={<NumberFormat value={values.runsUsed}/>}/>
                <HeaderItem label={"Exported rows"} value={<NumberFormat value={values.rowsUsed}/>}/>
                <HeaderItem label={"Failed runs"} value={<NumberFormat value={values.failedRuns}/>}/>
                <Spacer/>
            </Col>
        </Row>
    </Header>

}