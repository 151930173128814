import React, {useEffect} from 'react';
import {Table, Tabs} from "antd";

const sorter = (a, b) => {

    if (typeof a === "number") return a - b;
    if (typeof a === "string") return a.localeCompare(b);
    if (a instanceof Date) return new Date(a) - new Date(b); // Date sorting
    return 0;

}

export const ExportPreviewTable = ({data, columnNames}) => {

    const [columns, setColumns] = React.useState([]);

    useEffect(() => {

        if (!columnNames) {
            return;
        }

        setColumns(columnNames.map((item, index) => {
            return {
                title: item,
                dataIndex: item,
                key: index,
                width: 100,
                sorter: (a, b) => sorter(a[item], b[item]),
                ellipsis: true,
                onHeaderCell: () => ({
                    style: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, // Ellipsis for long headers
                }),
                onCell: () => ({
                    style: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, // Ellipsis for long cells
                }),
            }
        }));

    }, [columnNames]);

    return <Table dataSource={data} columns={columns}
                  pagination={{
                      pageSize: 50, // Set the number of rows per page
                      defaultCurrent: 1, // Default page to start on
                  }}
                  sticky={true} size={"small"} scroll={{
        y: '62vh', x: 'max-content',
    }}/>;

}