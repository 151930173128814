import React, {useEffect} from "react";
import {Button, Splitter} from "antd";
import {AIAssistantChat} from "./AIAssistantChat";
import {useParams} from "react-router-dom";
import {assistantService} from "../../../services/assistantService";
import {ExportPreview} from "./ExportPreview";
import {Spinner} from "../../../components/DesignSystem/Spinner/Spinner";
import {BackButton} from "../../../components/DesignSystem/BackButton/BackButton";
import {AlertIfMessage} from "../../../components/DesignSystem/AlertIfMessage/AlertIfMessage";
import {getErrorFromResponse} from "../../../utils/error.utils";

export const AIAssistantPage = ({onBack}) => {

    let {exportId} = useParams();

    const [preview, setPreview] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    useEffect(() => {

        assistantService.fetchPreview(exportId).then(response => {
            setPreview(response.data);
        }).catch(e => {
            setError(getErrorFromResponse(e));
        }).finally(() => {
            setLoading(false);
        });


    }, [exportId]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Spinner spinning={loading} tip={"Loading data..."}>
        <h4><BackButton onBack={onBack}/> AI Assistant</h4>
        <AlertIfMessage message={error}/>
        {error === null && (
            <Splitter>
                <Splitter.Panel defaultSize="60%" min="20%" max="70%" style={{marginRight: "10px"}}>
                    {preview && <ExportPreview data={preview}/>}
                </Splitter.Panel>
                <Splitter.Panel style={{background: "#FAFAFA"}}>
                    <AIAssistantChat exportId={exportId}/>
                </Splitter.Panel>
            </Splitter>
        )}
    </Spinner>

}
