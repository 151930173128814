import {Alert, Button, Col, Flex, Row, Tooltip} from "antd";
import {DashboardLabel} from "./DashboardLabel";
import {FormattedDateTime} from "../../../components/DesignSystem/Moment/FormattedDateTime";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {DashboardCol} from "./DashboardCol";
import React from "react";
import {MetadataCol} from "./MetadataCol";
import {DownloadOutlined} from "@ant-design/icons";


export const DashboardPanel = ({dashboard, lastSuccessfulExecution, onExportToPDF}) => {

    return <>
        <Row justify={"center"}>
            <Col sm={12}>
                <div style={{fontSize: '16px', fontWeight: '600'}}>{dashboard.title}</div>
            </Col>
            <Col sm={12}>
                <Flex justify={"right"} gap={4} align={"center"}>
                    <DashboardLabel title={'Data Refreshed On:'}/>
                    <span>
                  {lastSuccessfulExecution !== undefined &&
                      <FormattedDateTime date={lastSuccessfulExecution}/>}
              </span>
                    {dashboard.layout?.metadata?.map((item, index) => {
                      return <span key={index}>
                            <MetadataCol title={item.title} data={item.data} type={item.type} message={item.message}/>
                      </span>
                    })}
                    <Tooltip title={"Export dashboard to pdf"}><Button type={"text"} icon={<DownloadOutlined/>}
                                                                        size="small" onClick={onExportToPDF}/></Tooltip>
                </Flex>
            </Col>
        </Row>
        <Spacer border={true}/>

        {dashboard.message !== null && <Alert message={dashboard.message} type={"error"}/>}


        {dashboard.layout?.rows.map((item, index) => {
            return <div key={index}>
                <div key={index}>
                    <Row gutter={20}>
                        {item.cols.map((col, index) => {
                            return <Col sm={col.sm}><DashboardCol title={col.title} type={col.type} data={col.data}
                                                                  configuration={col.config}
                                                                  message={col.message}/></Col>
                        })}
                    </Row>
                </div>
                <Spacer size={"middle"}/>
            </div>
        })}</>

}